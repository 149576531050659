import { $API } from "boot/api";
import moment from 'moment';

export function sync({ commit, dispatch }) {
  return $API.CURRENT_USER_NOTIFICATIONS.GET({
      type: "unread"
    })
    .then(({ data }) => {
      commit("setNotifications", data.notifications);
      return data.notifications;
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("dispatch");
      }, 300);

      return [];
    });
}

export function pushNotification({ commit, state }, notification) {
  let notifications = [...state.notifications];
  notifications.unshift({
    created_on: moment().format('YYYY-MM-DD HH:mm:ss'),
    data: notification,
    is_read: false
  });
  commit("setNotifications", notifications);
}

export function removeNotification({ commit, state }, notificationId) {
  let index = state.notifications.findIndex(notification => notification.data.id === notificationId);
  if (index >= 0) {
    let notifications = [...state.notifications];
    notifications.splice(index, 1);
    commit("setNotifications", notifications);
  }
}

export function markAllRead({ commit }) {
  $API.CURRENT_USER_NOTIFICATIONS.POST('mark-read')
    .then(({ data }) => {
      commit("setNotifications", data.notifications);
    });
}

export function markRead({ commit }, notificationId) {
  $API.CURRENT_USER_NOTIFICATIONS.POST(`${notificationId}/mark-read`)
    .then(({ data }) => {
      commit("updateNotification", data.notification);
    });
}
