export function setSearch(state, text) {
  state.search = text;
}
export function setCategoryType(state, type) {
  state.category_type = type;
}
export function setCategory(state, category) {
  state.category = category;
}
export function setLicense(state, license) {
  state.license = license;
}
export function setMinPrice(state, price) {
  state.min_price = price;
}
export function setMaxPrice(state, price) {
  state.max_price = price;
}
export function setInventoryGroupingAvailable(state, available) {
  if (typeof state.inventory_grouping !== "undefined") {
    if (state.inventory_grouping.available !== available) {
      state.inventory_grouping.available = available;
    }
  } else {
    state.inventory_grouping = {
      available,
      value: null,
    };
  }
}
export function setInventoryGroupingValue(state, value) {
  if (typeof state.inventory_grouping !== "undefined") {
    if (state.inventory_grouping.value !== value) {
      state.inventory_grouping.value = value;
    }
  } else {
    state.inventory_grouping = {
      available: false,
      value,
    };
  }
}

export function setListingAction(state, action) {
  state.listingAction = action;
}

export function setEntryPoint(state, action) {
  state.entryPoint = action;
}

export function resetListingAction(state, action) {
  state.listingAction = null;
}

export function clearEntryPoint(state, action) {
  state.entryPoint = null;
}
