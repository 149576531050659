import { $API } from "boot/api";

export function syncTaxonomies({ dispatch }) {
  //Sync category images taxonomy.
  dispatch("syncCategoryImages");
  //Sync category type taxonomy.
  dispatch("syncCategoryTypes");
  //Sync category taxonomy.
  dispatch("syncCategories");
  //Sync trim type taxonomy.
  dispatch("syncTrimTypes");
  //Sync strain type taxonomy.
  dispatch("syncStrainTypes");
  //Sync production type taxonomy.
  dispatch("syncProductionTypes");
  //Sync company brands.
  dispatch("syncBrands");
  //Sync payment terms.
  dispatch("syncPaymentTerms");
  //Sync grow media.
  dispatch("syncGrowMedia");
  //sync filter cateogries.
  dispatch("syncFilterCategories");
}

export function syncCategoryImages({ commit, dispatch }, retryCount = 0) {
  const maxRetries = 3;
  const retryDelay = 300;

  $API.PUBLIC_IMAGES.GET()
    .then(({ data }) => {
      commit("setCategoryImages", data.category_images);
    })
    .catch((response) => {
      console.log("ERR", response);
      if (retryCount < maxRetries) {
        setTimeout(() => {
          dispatch("syncCategoryImages", retryCount + 1);
        }, retryDelay);
      } else {
        console.log("Max retries reached, giving up.");
      }
    });
}

export function syncCategoryTypes({ commit, dispatch }) {
  $API.CATEGORY_TYPES.GET()
    .then(({ data }) => {
      commit("setCategoryTypes", data.category_types);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncCategoryTypes");
      }, 200);
    });
}

export function syncCategories({ commit, dispatch }, { items = null } = {}) {
  $API.CATEGORIES.GET()
    .then(({ data }) => {
      commit("setCategories", data.categories);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncCategories", { items });
      }, 200);
    });
}

export function syncFilterCategories(
  { commit, dispatch },
  { items = null } = {}
) {
  $API.CATEGORIES.GET()
    .then(({ data }) => {
      if (Array.isArray(items) && items.length > 0) {
        const uniqueCategoryTypes = items.reduce((acc, item) => {
          if (
            item.category_type &&
            !acc.find((ct) => ct.id === item.category_type.id)
          ) {
            acc.push(item.category_type);
          }
          if (item.product && item.product.category) {
            acc.push(item.product.category);
          }
          return acc;
        }, []);

        const filteredCategories = data.categories.filter((category) =>
          uniqueCategoryTypes.some((ct) => ct.id === category.id)
        );

        commit("setFilterCategories", filteredCategories);
      } else {
        console.log("Invalid or empty items array:", items);
        commit("setFilterCategories", data.categories);
      }
    })
    .catch((error) => {
      console.error("Failed to sync filter categories:", error);
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncFilterCategories", { items });
      }, 200);
    });
}

export function syncTrimTypes({ commit, dispatch }) {
  $API.TRIM_TYPES.GET()
    .then(({ data }) => {
      commit("setTrimTypes", data.trim_types);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncTrimTypes");
      }, 200);
    });
}

export function syncStrainTypes({ commit, dispatch }) {
  $API.STRAIN_TYPES.GET()
    .then(({ data }) => {
      commit("setStrainTypes", data.strain_types);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncStrainTypes");
      }, 200);
    });
}

export function syncProductionTypes({ commit, dispatch }) {
  $API.PRODUCTION_TYPES.GET()
    .then(({ data }) => {
      commit("setProductionTypes", data.production_types);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncProductionTypes");
      }, 200);
    });
}

export function syncBrands({ commit, dispatch }) {
  $API.BRANDS.GET({
    my: true, //load only current company brands
  })
    .then(({ data }) => {
      commit("setBrands", data.brands);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncBrands");
      }, 200);
    });
}

export function syncLicenses({ commit, dispatch, rootState }) {
  $API.CURRENT_COMPANY_LICENSES.GET()
    .then(({ data }) => {
      commit("setLicenses", data.licenses);
      //get current license from a "current" module.
      let currentLicense = rootState.current.license;
      if (!currentLicense) {
        //set first license as a current license.
        commit("current/setLicense", data.licenses[0], { root: true });
      } else {
        //find current license in all licenses list.
        let currentLicenseFromList = data.licenses.find(
          (l) => currentLicense.id === l.id
        );
        //check that current license is available.
        if (!currentLicenseFromList) {
          //clear listing chat.
          commit("listingV2/clearFormData", null, { root: true });
          //set first license as a current license.
          commit("current/setLicense", data.licenses[0], { root: true });
        } else {
          //update current license in state so that we always have an updated object.
          commit("current/setLicense", currentLicenseFromList, { root: true });
        }
      }
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncLicenses");
      }, 200);
    });
}

export function syncCompanyContacts({ commit, dispatch }) {
  $API.CURRENT_COMPANY_CONTACTS.GET()
    .then(({ data }) => {
      commit("setCompanyContacts", data.contacts);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncCompanyContacts");
      }, 200);
    });
}

export function syncPaymentTerms({ commit, dispatch }) {
  $API.PAYMENT_TERMS.GET()
    .then(({ data }) => {
      commit("setPaymentTerms", data.payment_terms);
    })
    .catch(() => {
      //Sync again on a time out.
      setTimeout(() => {
        dispatch("syncPaymentTerms");
      }, 200);
    });
}

export function syncGrowMedia({ commit, dispatch }, retryCount = 0) {
  $API.GROW_MEDIA.GET()
    .then(({ data }) => {
      commit("setGrowMedia", data.grow_media);
    })
    .catch((error) => {
      if (retryCount < 5) {
        let timeout = Math.pow(2, retryCount) * 1000;
        setTimeout(() => {
          dispatch("syncGrowMedia", retryCount + 1);
        }, timeout);
      } else {
        console.error(
          "Failed to sync grow media after several attempts:",
          error
        );
      }
    });
}

export function addTempShippingBox({ commit, state }, box) {
  if (box && Object.keys(box).length > 0) {
    let currentKey = state.company.tempBoxes.length;
    box.id = `temp-${currentKey}`;
    commit("addTempBox", box);
    return box;
  }
}

export function editTempShippingBox({ commit, state }, box) {
  if (box && Object.keys(box).length > 0 && box.id) {
    commit("editTempBox", box);
    return box;
  }
}

export function updateInventoryCategoryTypesOG({ commit }, items) {
  return new Promise((resolve, reject) => {
    const uniqueCategoryTypes = items.reduce((acc, item) => {
      if (
        item.category_type &&
        !acc.find((ct) => ct.id === item.category_type.id)
      ) {
        acc.push(item.category_type);
      }

      if (
        item.product &&
        item.product.category_type &&
        !acc.find((ct) => ct.id === item.product.category_type.id)
      ) {
        acc.push(item.product.category_type);
      }

      return acc;
    }, []);

    commit("setInventoryCategoryTypes", uniqueCategoryTypes);
    resolve(uniqueCategoryTypes);
  });
}

export function updateInventoryCategoryTypes({ commit }, items) {
  return new Promise((resolve, reject) => {
    if (!Array.isArray(items)) {
      console.error("Expected an array, but received:", items);
      reject("Data format error: Expected an array");
      return;
    }

    // Process only if items is an array
    const uniqueCategoryTypes = items.reduce((acc, item) => {
      if (
        item.category_type &&
        !acc.find((ct) => ct.id === item.category_type.id)
      ) {
        acc.push(item.category_type);
      }

      if (
        item.product &&
        item.product.category_type &&
        !acc.find((ct) => ct.id === item.product.category_type.id)
      ) {
        acc.push(item.product.category_type);
      }

      return acc;
    }, []);

    commit("setInventoryCategoryTypes", uniqueCategoryTypes);
    resolve(uniqueCategoryTypes);
  });
}

export function fetchAllData({ commit, dispatch }) {
  commit("setLoading", true);
  Promise.all([$API.AUCTIONS.GET(), $API.INVENTORY_LISTINGS.GET()])
    .then(([auctionsResponse, listingsResponse]) => {
      const brands = [];

      auctionsResponse.data.auctions.forEach((auction) => {
        if (auction.brand && !brands.some((b) => b.id === auction.brand.id)) {
          brands.push(auction.brand);
        }
      });

      listingsResponse.data.inventory_listings.forEach((listing) => {
        if (listing.brand && !brands.some((b) => b.id === listing.brand.id)) {
          brands.push(listing.brand);
        }
      });

      commit("setAllBrands", brands);
      commit("setLoading", false);
      dispatch("fetchAndSetUserBrands");
    })
    .catch((error) => {
      console.error("Failed to fetch data:", error);
    });
}

export function fetchAndSetUserBrands({ commit }) {
  $API.BRANDS.GET({ my: true })
    .then((response) => {
      const userBrandIds = response.data.brands.map((brand) => brand.id);
      commit("setUserBrands", userBrandIds);
    })
    .catch((error) => {
      console.error("Error fetching user brands:", error);
    });
}

export function fetchBrandDetails({ state, commit }, brandId) {
  if (!state.auctionsByBrand[brandId]) {
    $API.AUCTIONS.GET({ brand: brandId })
      .then((response) => {
        commit("setAuctionsForBrand", { brandId, auctions: response.data });
      })
      .catch((error) => {
        console.error(
          `Failed to fetch auctions for brand ID: ${brandId}`,
          error
        );
      });
  }

  if (!state.listingsByBrand[brandId]) {
    $API.INVENTORY_LISTINGS.GET({ brand: brandId })
      .then((response) => {
        const listings = response.data.inventory_listings;
        const productFetches = listings.map((listing) =>
          $API.PRODUCTS.GET(listing.product_id)
            .then((productResponse) => {
              const productLineName =
                productResponse.data.product.product_line?.name;
              return {
                ...listing,
                product_line_name: productLineName || "No product line",
              };
            })
            .catch((error) => {
              console.error(
                `Failed to fetch product details for product ID ${listing.product_id}`,
                error
              );
              return { ...listing, product_line_name: "No product line" };
            })
        );

        Promise.all(productFetches).then((listings) => {
          commit("setListingsForBrand", {
            brandId,
            listings: listings,
          });
        });
      })
      .catch((error) => {
        console.error(
          `Failed to fetch listings for brand ID: ${brandId}`,
          error
        );
      });
  }
}
